<template>
    <Layout>
        <div class="row">
            <div class="col-md-6 mb-3">
                <label class="form-label">Nome <span class="text-danger">*</span></label>
                <input type="text" class="form-control" placeholder="Nome" v-model="user.name">
            </div>
            <div class="col-12 col-md-6 mb-3">
                <label for="email" class="form-label">E-mail <span class="text-danger">*</span></label>
                <b-form-input type="text" v-model="user.email" required placeholder="E-mail"/>
            </div>
            <div class="col-12 col-md-3 mb-3">
                <label for="cpf" class="form-label">CPF</label>
                <b-form-input type="text" v-model="user.cpf" required placeholder="CPF" v-maska="'###.###.###-##'"/>
            </div>

            <div class="col-12 col-md-2 mb-3">
                <label for="phone" class="form-label">Telefone <span class="text-danger">*</span></label>
                <b-form-input type="text" v-model="user.phone" required placeholder="Telefone"
                              v-maska="['(##) #####-####', '(##) ####-####']"/>
            </div>

            <div class="col-12 col-md-2 mb-3">
                <label for="cep" class="form-label">CEP <span class="text-danger">*</span></label>
                <b-form-input type="text" v-model="user.cep" required placeholder="CEP" v-maska="'##.###-###'"
                              @keyup="loadAddress"/>
            </div>

            <div class="col-12 col-md-2 mb-3">
                <label for="uf" class="form-label">UF <span class="text-danger">*</span></label>
                <b-form-input type="text" v-model="user.uf" required placeholder="UF" maxlenght="2"/>
            </div>

            <div class="col-12 col-md-3 mb-3">
                <label for="city" class="form-label">Cidade <span class="text-danger">*</span></label>
                <b-form-input type="text" v-model="user.city" required placeholder="Cidade" maxlenght="2"/>
            </div>

            <div class="col-12 col-md-3 mb-3">
                <label for="neighborhood" class="form-label">Bairro <span class="text-danger">*</span></label>
                <b-form-input type="text" v-model="user.neighborhood" required placeholder="Bairro"/>
            </div>

            <div class="col-12 col-md-4 mb-3">
                <label for="street" class="form-label">Logadouro <span class="text-danger">*</span></label>
                <b-form-input type="text" v-model="user.street" required placeholder="Logadouro"/>
            </div>

            <div class="col-12 col-md-2 mb-3">
                <label for="number" class="form-label">Número <span class="text-danger">*</span></label>
                <b-form-input type="text" v-model="user.number" required placeholder="Número"/>
            </div>

            <div class="col-12 col-md-3 mb-3">
                <label for="complement" class="form-label">Complemento</label>
                <b-form-input type="text" v-model="user.complement" placeholder="Complemento"/>
            </div>

            <div class="col-12 col-md-6 mb-3">
                <label for="instagram" class="form-label">Instagram</label>
                <b-form-input type="text" v-model="user.instagram" placeholder="Instagram"/>
            </div>

            <div class="col-12">
                <b-form-group
                    label-for="invalidCheck01">
                    <b-form-checkbox
                        id="invalidCheck01"
                        v-model="user.agree"
                        name="agree"
                        required>
                        Aceito os termos e condições <span class="text-danger">*</span>
                    </b-form-checkbox>
                </b-form-group>
            </div>
            <div class="col-12">
                <button class="btn btn-primary">Salvar</button>
            </div>
        </div>
    </Layout>
</template>

<script>

import $ from 'jquery';
import Layout from "@/components/layout/main.vue";

export default {
    components: {
        Layout,
    },
    data() {
        return {
            user: {
                name: '',
                email: '',
                cpf: '',
                phone: '',
                cep: '',
                uf: '',
                city: '',
                neighborhood: '',
                street: '',
                number: '',
                complement: '',
                instagram: '',
                agree: ''
            }
        }
    },
    methods: {
        loadAddress() {
            var self = this;
            var cep = self.user.cep.replace(/\D/g, '');
            var validacep = /^[0-9]{8}$/;
            if (validacep.test(cep)) {
                $.getJSON("https://viacep.com.br/ws/" + cep + "/json/?callback=?", function (dados) {
                    if (!("erro" in dados)) {
                        self.user.street = dados.logradouro;
                        self.user.uf = dados.uf;
                        self.user.city = dados.localidade;
                        self.user.neighborhood = dados.bairro;
                        self.user.complement = dados.complemento;
                    }
                });
            }
        }
    }
}

</script>
